import EService from '~/parts/core/services/eService';
import { type PersonEntityFilter,
    type UserAccount, type UserAuth, type UserRef
} from '~/parts/users';
import { type Filter } from "~/parts/entityFilter";
import {
    type DisplayableRef, type ReferentialInfo, type Referential
} from '~/parts/referentials';
import PermissionsConstantsProvider
    from "~/parts/users/permissions/permissionsConstantsProvider";
import UsersConstantsProvider
    from "~/parts/users/usersConstantsProvider";
import { InputType } from '~/parts/core';


export function userItemText(item: User) {
    const name = `${item.firstName || ""} ${item.lastName || ""}`;
    return name.trim() == "" ? item.email : name;
}

class UsersService extends EService {

    constructor(useApiKey: boolean = false) {
        super(useApiKey);
    }

    static getReferentialsInfos(): Array<ReferentialInfo> {
        return [{
            typeName: UsersConstantsProvider.UserTypeName,
            metaData: [
                { text: "#", value: "id", isKey: true },
                { textKey: "firstName", value: "firstName", inputOption: { type: InputType.Text } },
                { textKey: "lastName", value: "lastName", inputOption: { type: InputType.Text } },
                { textKey: "email", value: "email", inputOption: { type: InputType.Text } },
                { textKey: "activated", value: "isActivated", visibleOn: "readOnly", inputOption: { type: InputType.Custom } },
                {
                    textKey: "accesses", value: "accesses", inputOption: {
                        type: InputType.Dynamic, selectMode: "multiple",
                        lookup: { typeName: PermissionsConstantsProvider.AccessTypeName, valueField: "id", textField: "name" }
                    }
                },
                { textKey: "password", visibleOn: "readOnly", custom: { cellTemplateName: "password" } },
                {
                    textKey: "filters", visibleOn: "editOnly", value: "filters",
                    custom: { editTemplateName: "filters" }
                }
            ],
            helpers: {
                getSearchablePropertiesValues: (item: User, lookups: { [key: string]: Array<Referential> }) => {
                    const searchablePropertiesValues = [item.id, item.email, item.firstName, item.lastName];
                    const lookupData = lookups.accesses as Array<Access>;
                    const accesses = lookupData.filter(a => item.accesses.includes(a.id));
                    if (!!accesses)
                        searchablePropertiesValues.push(...accesses.map(p => p.name));
                    return searchablePropertiesValues;
                }
            }
        }];
    }

    async getAll() {
        var persons = await this.http.get<Array<User>>('persons/all/');
        return persons;
    }

    async saveUserFilters(user: UserRef) {
        if (user.filters) {
            const filters = Object.entries(user.filters).map(curr => {
                const [key, value] = curr;
                const allSelected = (value as Array<number>).some(f => f == 0);
                const entityFilters = allSelected ? [{ id: 0 } as DisplayableRef] :
                    (value as Array<number>).map(cu => ({ id: cu } as DisplayableRef));
                return {
                    type: key,
                    Filters: entityFilters
                } as Filter;
            });
            const userFilters: PersonEntityFilter = {
                personId: user.id,
                entityFilter: filters
            };
            return await this.saveFilters(userFilters);
        }
        return true;
    }

    async saveUserAccount(userAccount: UserAccount) {
        const accountResult = await this.http.put<UserAccount, UserAccount>("persons/saveAccount", userAccount);
        return accountResult;
    }

    async save(user: User) {
        const personResponse = await this.http.post<User, User>('persons/save/', user);
        const filterResult = personResponse ? await this.saveUserFilters(user) : false;
        return personResponse && filterResult ? personResponse : false;
    }

    async delete(user: User) {
        const response = await this.http.post(`persons/delete/${user.id}/`);
        return response;
    }

    async activateAccount(user: User) {
        const response = await this.http.post(`persons/activate/${user.id}`);
        return response;
    }

    async desableAccount(user: User) {
        const response = await this.http.post(`persons/deActivate/${user.id}/`);
        return response;
    }

    async sendRecoveryRequest(user: User) {
        const response = await this.http.post<User, User>(`persons/passwordRecoveryRequest/`, user);
        return response;
    }

    async checkPasswordRecoveryToken(token: string) {
        const response = await this.http.get<boolean>(`persons/checkPasswordRecoveryToken?token=${token}`);
        return response;
    }

    async passwordRecovery(user: UserAuth, token: string) {
        const response = await this.http.put<UserAuth, UserAuth>(`persons/passwordRecovery?token=${token}`, user);
        return response;
    }

    async register(user: UserAuth) {
        const response = await this.http.post<UserAuth, UserAuth>(`authentication/singUp`, user);
        return response;
    }

    async saveFilters(userFilters: PersonEntityFilter) {
        const response = await this.http.put<PersonEntityFilter, PersonEntityFilter>(`persons/saveFilters`, userFilters);
        return response;
    }

    override toString(user: User, isInitiale: boolean = false) {
        const names = [user.firstName, user.lastName, user.email]
            .filter(n => !!n).map(n => (n as string));
        if (isInitiale) {
            return names.slice(0, 2).map(n => n.charAt(0)).join(" ").toUpperCase();
        }
        return names.slice(0, 2).join(" ");
    }
}

export default UsersService;
